import { ChakraProps, colors } from "@biblioteksentralen/react";
import { featureToggleEnabled } from "@libry-content/common";
import { CustomPalette } from "@libry-content/types";
import { ReactNode, createContext, useContext } from "react";
import { useCommonData } from "../components/layout/CommonDataProvider";
import { isBright as getColorIsBright } from "./colors";
import { ResolvedSite } from "../components/layout/commonData";

export type ResolvedPaletteColor = {
  css: {
    background: string;
    color: string;
  } & Pick<ChakraProps, "_before">;
  isBright: boolean;
};

export type ColorPaletteKeys = keyof Omit<CustomPalette, "_type" | "useCustomPalette">;

export type ColorPalette = { useCustomPalette: boolean; colors: Record<ColorPaletteKeys, ResolvedPaletteColor> };

const defaultColors: Record<ColorPaletteKeys, string> = {
  header: colors.white,
  search: colors.lightPurple,
  footer: colors.grey05,
  card: colors.grey10,
  pop1: colors.accentYellow,
  pop2: colors.accentBlueMedium,
  darkaccent1: colors.accentPurple,
  lightaccent1: colors.lightPurple,
  darkaccent2: colors.accentGreen,
  lightaccent2: colors.lightGreen,
  darkaccent3: colors.accentPeach,
  lightaccent3: colors.lightPeach,
  darkaccent4: colors.accentBlueDark,
  lightaccent4: colors.lightBlue,
};

const colorPaletteKeys = Object.keys(defaultColors) as ColorPaletteKeys[];

export const createSitePalette = (site?: ResolvedSite): ColorPalette => {
  const useCustomPalette = !!site?.customPalette?.useCustomPalette;

  const colors = colorPaletteKeys.reduce(
    (acc, key) => ({
      ...acc,
      [key]: getColorFor(key, useCustomPalette, site),
    }),
    {} as Record<ColorPaletteKeys, ResolvedPaletteColor>
  );

  return {
    colors,
    useCustomPalette,
  };
};

const getColorFor = (key: ColorPaletteKeys, useCustomPalette: boolean, site?: ResolvedSite): ResolvedPaletteColor => {
  const color = (useCustomPalette && site?.customPalette?.[key]?.color) || defaultColors[key];

  const isBright = getColorIsBright(color);

  return {
    css: {
      background: color,
      color: isBright ? "gray.800" : "white",
      _before: featureToggleEnabled("custom-palette-debug")
        ? {
            content: `"${key} ${color}"`,
            opacity: 0.7,
            fontSize: ".6rem",
            position: "absolute",
          }
        : undefined,
    },
    isBright: isBright,
  };
};

// Lager egen context og provider for sitePalette så vi kan beregne palatten en gang på toppnivå istedenfor hver gang den brukes (som ble voldsomt mange beregninger)
const PaletteContext = createContext<ColorPalette>(createSitePalette());

export const useSitePalette = () => useContext(PaletteContext);

export const PaletteProvider = (props: { children: ReactNode }) => {
  const { site } = useCommonData();
  const sitePlatte = createSitePalette(site);

  return <PaletteContext.Provider value={sitePlatte}>{props.children}</PaletteContext.Provider>;
};
