import(/* webpackMode: "eager", webpackExports: ["NotFoundClientSide"] */ "/vercel/path0/apps/frontend/src/app/[locale]/not-found.client.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next-sanity@9.4.2_@sanity+client@6.20.1_@sanity+icons@3.2.0_@sanity+types@3.47.1_@sanity+ui@2_66lralk4ycxcth7i27swu3iubq/node_modules/next-sanity/dist/visual-editing/client-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.5_@babel+core@7.23.3_react-dom@18.3.1_react@18.3.1/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.5_@babel+core@7.23.3_react-dom@18.3.1_react@18.3.1/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/common/src/isInsideAnIframe.ts");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/common/src/useIsIframeReady.ts");
